import React, { Component } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';

class AnalytesDefaultValues extends Component {
    constructor(props) {
        super();
        this.handleChangeDevice = this.handleChangeDevice.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeLab = this.handleChangeLab.bind(this);
        const selectedLab = props.labs[0];
        const selectedDevice = selectedLab && selectedLab.devices ? selectedLab.devices[0] : { id: 0 };
        this.state = {
            val: {
                lab: selectedLab ? selectedLab : '0',
                device: selectedDevice,
                date: new Date(),
            },

        }
    }

    handleChangeLab(e) {
        console.log(e.target.value);
        const selectedLab = this.props.labs.filter(v => v.id === e.target.value)[0];
        console.log(selectedLab);
        const selectedDevice = selectedLab && selectedLab.devices ? selectedLab.devices[0] : { id: 0 };
        console.log(this.state);
        let v = { ...this.state.val, lab: selectedLab, device: selectedDevice };
        this.setState({
            val: v
        });
        console.log(v);
        this.props.handleChangeForm(v);
    }

    handleChangeDevice(e) {
        const selectedDevice = this.state.val.lab.devices.filter(v => v.id === e.target.value)[0];
        let v = { ...this.state.val, device: selectedDevice }
        this.setState({
            val: v
        });
        console.log(v);
        this.props.handleChangeForm(v);
    }

    handleChangeDate(date) {
        let v = { ...this.state.val, date: date };
        this.setState({
            val: v
        });
        console.log(v);
        this.props.handleChangeForm(v);
    }

    render() {
        const { labs, searchForTheNewMeasurement, classes } = this.props;
        const selectedLab = this.state.val['lab'] ? this.state.val['lab'] : labs[0];
        return (
            <div>
                <h1>Survey</h1>
                <form>
                    <TextField
                        required
                        id={"labid"}
                        value={this.state.val['lab'].id ?? ""}
                        name={"lab"}
                        select
                        label={"Lab"}
                        onChange={this.handleChangeLab}
                        //  helperText={"Select lab"}
                    >
                        {labs.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    < TextField
                        required
                        id={"device"}
                        name={"device"}
                        // defaultValue={this.state.val['device']}
                        value={this.state.val['device'].id ?? ""}
                        select
                        label={"Device"}
                        onChange={this.handleChangeDevice}
                        // helperText={"Select device"}
                    >
                        {selectedLab && selectedLab.devices &&
                            selectedLab.devices.map((option) => (
                                <MenuItem key={selectedLab.id + option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            required
                            className={classes.datetime}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date"
                            autoOk={true}
                            name="date"
                            label="Date"
                            value={this.state.val['date']}
                            onChange={this.handleChangeDate}
                            // helperText={"Select date"}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {/* <TextField
                        id="date"
                        label="Birthday"
                        type="date"
                        // defaultValue="2017-05-24"
                        value={this.state.val['date']}
                        className={classes.textField}
                        onChange={this.handleChangeDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> */}
                </form>
            </div >
        );
    }
}

export default AnalytesDefaultValues;