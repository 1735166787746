import React, { Component } from "react";
import { readString } from 'react-papaparse';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress';
class Import extends Component {
    constructor(props) {
        super();
    }

    state = {
        task: "",
        checked: "false",
        counter: 0,
        max: 0,
        data: {},
        element: null,
        firstLine: '',
        timer: null,
        open: false
    };
    val = '"date";"day";"result";"unit";"parameter";"device";"device-model";"device-vendor";"lab";"user";"device-id"';
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    importelement = (element, firstLine) => {
        console.log('debug6');
        let data = {};
        data = {
            date: element[firstLine.indexOf('date')] ? element[firstLine.indexOf('date')] : "",
            lab: element[firstLine.indexOf('lab')] ? element[firstLine.indexOf('lab')] : "",
            day: element[firstLine.indexOf('day')] ? element[firstLine.indexOf('day')] : "",
            result: element[firstLine.indexOf('result')] ? element[firstLine.indexOf('result')] : "",
            unit: element[firstLine.indexOf('unit')] ? element[firstLine.indexOf('unit')] : "",
            parameter: element[firstLine.indexOf('parameter')] ? element[firstLine.indexOf('parameter')] : "",
            device: element[firstLine.indexOf('device')] ? element[firstLine.indexOf('device')] : "",
        }
        this.setState({ element: data });
        let d = this.props.measurements.filter(
            v => v.parameter === data.parameter
                && v.lab_name === data.lab
                && v.device_name === data.device
        )[0]
        if (d) {
            console.log('debug7');
            let a = d.results.concat({
                date: data.date,
                result: data.result,
                day: 'n',
            });
            // for (let i = 0; i < 500; i++) {
            //     a = a.concat({
            //         date: data.date,
            //         result: data.result,
            //         day: 'n',
            //     });
            //   }
            console.log('debug8', a);
            this.props.importAdd(a, d.id);
        } else {
            console.log('debug9');
            let l = this.props.labs.filter(v => v.name === data.lab)[0]
            let d = l.devices.filter(v => v.name === data.device)[0]
            let newMeasure = {
                device: d.id,
                lab: l.id,
                lab_name: data.lab,
                device_name: data.device,
                parameter: data.parameter,
                unit: data.unit,
                name: data.lab + '-' + data.device + '-' + data.parameter,
                results: [
                    {
                        date: data.date,
                        result: data.result,
                        day: 'weekday[form.date.getDay()]'
                    }
                ]
            }
            console.log('debug10', newMeasure);
            this.props.addMeasure(newMeasure);
        }
    }

    incrementCounter() {
        console.log('debug5');
        const { counter, data, firstLine } = this.state;
        if (this.props.state.loading) {
            // this.setState({ counter: 0 });
        } else {
            let element = data[counter];
            console.log(element);
            this.importelement(element, firstLine);
            this.setState({ counter: counter + 1 });
        }


        console.log(counter);
        if (counter === data.length - 1) {
            this.setState({ counter: 0 });
            console.log('STOPPPP');
            this.setState({ open: false });
            clearInterval(this.state.timer);
        }

    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('debug1');
        this.setState({ open: true });
        let data = {};
        
        const results = readString(this.state.task, { delimiter: ";" });
        const firstLine = results.data.shift();
        const thisBoundedIncrementer = this.incrementCounter.bind(this);
        let timer = setInterval(thisBoundedIncrementer, 250);
        this.setState({ timer: timer, data: results.data, firstLine: firstLine });
        // results.data.forEach(element => {
        //     data = {
        //         date: element[firstLine.indexOf('date')] ? element[firstLine.indexOf('date')] : "",
        //         lab: element[firstLine.indexOf('lab')] ? element[firstLine.indexOf('lab')] : "",
        //         day: element[firstLine.indexOf('day')] ? element[firstLine.indexOf('day')] : "",
        //         result: element[firstLine.indexOf('result')] ? element[firstLine.indexOf('result')] : "",
        //         unit: element[firstLine.indexOf('unit')] ? element[firstLine.indexOf('unit')] : "",
        //         parameter: element[firstLine.indexOf('parameter')] ? element[firstLine.indexOf('parameter')] : "",
        //         device: element[firstLine.indexOf('device')] ? element[firstLine.indexOf('device')] : "",
        //     }
        //     let d = this.props.measurements.filter(
        //         v => v.parameter === data.parameter
        //             && v.lab_name === data.lab
        //             && v.device_name === data.device
        //     )[0]
        //     console.log('debug2');
        //     if (d) {
        //         console.log('debug3');
        //         let a = d.results.concat({
        //             date: data.date,
        //             result: data.result,
        //             day: 'n',
        //         });
        //         // for (let i = 0; i < 100; i++) {
        //         //     a = a.concat({
        //         //         date: data.date,
        //         //         result: data.result,
        //         //         day: 'n',
        //         //     });
        //         // }
        //         // this.props.importAdd(a, d.id);
        //     } else {
        //         console.log('debug4');
        //         let l = this.props.labs.filter(v => v.name === data.lab)[0]
        //         let d = l.devices.filter(v => v.name === data.device)[0]
        //         let newMeasure = {
        //             device: d.id,
        //             lab: l.id,
        //             lab_name: data.lab,
        //             device_name: data.device,
        //             parameter: data.parameter,
        //             unit: data.unit,
        //             name: data.lab + '-' + data.device + '-' + data.parameter,
        //             results: [
        //                 {
        //                     date: data.date,
        //                     result: data.result,
        //                     day: 'weekday[form.date.getDay()]'
        //                 }
        //             ]
        //         }
        //         this.props.addMeasure(newMeasure);
        //     }
        // });
    };
    handleSubmit100 = (e) => {
        let data = {};
        e.preventDefault();
        const results = readString(this.state.task, { delimiter: ";" });
        const firstLine = results.data.shift();
        // const thisBoundedIncrementer = this.incrementCounter.bind(this);
        // let timer = setInterval(thisBoundedIncrementer, 100);
        // this.setState({ timer: timer, data: results.data, firstLine: firstLine });
        results.data.forEach(element => {
            data = {
                date: element[firstLine.indexOf('date')] ? element[firstLine.indexOf('date')] : "",
                lab: element[firstLine.indexOf('lab')] ? element[firstLine.indexOf('lab')] : "",
                day: element[firstLine.indexOf('day')] ? element[firstLine.indexOf('day')] : "",
                result: element[firstLine.indexOf('result')] ? element[firstLine.indexOf('result')] : "",
                unit: element[firstLine.indexOf('unit')] ? element[firstLine.indexOf('unit')] : "",
                parameter: element[firstLine.indexOf('parameter')] ? element[firstLine.indexOf('parameter')] : "",
                device: element[firstLine.indexOf('device')] ? element[firstLine.indexOf('device')] : "",
            }
            let d = this.props.measurements.filter(
                v => v.parameter === data.parameter
                    && v.lab_name === data.lab
                    && v.device_name === data.device
            )[0]
            if (d) {

                let a = d.results.concat({
                    date: data.date,
                    result: data.result,
                    day: 'n',
                });
                for (let i = 0; i < 99; i++) {
                    a = a.concat({
                        date: data.date,
                        result: data.result,
                        day: 'n',
                    });
                }
                this.props.importAdd(a, d.id);
            } else {
                let l = this.props.labs.filter(v => v.name === data.lab)[0]
                let d = l.devices.filter(v => v.name === data.device)[0]
                let newMeasure = {
                    device: d.id,
                    lab: l.id,
                    lab_name: data.lab,
                    device_name: data.device,
                    parameter: data.parameter,
                    unit: data.unit,
                    name: data.lab + '-' + data.device + '-' + data.parameter,
                    results: [
                        {
                            date: data.date,
                            result: data.result,
                            day: 'weekday[form.date.getDay()]'
                        }
                    ]
                }
                this.props.addMeasure(newMeasure);
            }
        });
    };
    render() {
        const { classes } = this.props;
        return (
            <>
                <form
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    style={{ marginTop: "30px" }}
                    onSubmit={this.handleSubmit}
                >
                    <legend> </legend>
                    <div className="form-group">
                        <h1 htmlFor="task">Import CSV data</h1>

                        <TextField
                            rowsMin={30}
                            id="task"
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue={this.val}
                            onChange={this.handleChange}
                            inputProps={{ className: classes.textarea }}
                            multiline
                            // classes={{ paper: classes.textarea }}
                            variant="outlined"
                            rows={10}
                        />
                    </div>
                    <Button
                        type='submit'
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Import
                    </Button>
                </form>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleSubmit100}
                >
                    Import 100
                </Button>
                <Dialog classes={{ paper: classes.dialog }}
                    open={this.state.open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Import"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.counter}/{this.state.data.length}
                            {this.state.element && <div>
                                <div>Date: {this.state.element.date}</div>
                                <div>Lab: {this.state.element.lab}</div>
                                <div>Device: {this.state.element.device}</div>
                                <div>Parameter: {this.state.element.parameter}</div>
                                <div>Result: {this.state.element.result}</div>
                            </div>
                            }
                            <LinearProgress variant="determinate" value={this.state.data.length > 0 ? 100 * (this.state.counter / this.state.data.length) : 0} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Agree
                        </Button> */}
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default Import;
