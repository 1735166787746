import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Add } from '@material-ui/icons';
class TableExpandingRows extends Component {
    state = {
        open: false
    };
    roughSizeOfObject(object) {

        var objectList = [];
        var stack = [object];
        var bytes = 0;

        while (stack.length) {
            var value = stack.pop();

            if (typeof value === 'boolean') {
                bytes += 4;
            }
            else if (typeof value === 'string') {
                bytes += value.length * 2;
            }
            else if (typeof value === 'number') {
                bytes += 8;
            }
            else if
                (
                typeof value === 'object'
                && objectList.indexOf(value) === -1
            ) {
                objectList.push(value);

                for (var i in value) {
                    stack.push(value[i]);
                }
            }
        }
        return bytes*3;
    }
    render() {
        const { measure, params, index, classes } = this.props;
        if (measure.results.length > 100)
            console.log(measure);
        return (
            <>
                <TableRow hover onClick={() => {
                    this.setState({ open: !this.state.open });
                }}>
                    <TableCell>
                        <div>
                            {measure.lab_name}/{measure.device_name}
                            <span className={measure.results.length>100 ? classes.alert: classes.normal}>[{measure.results.length}]{this.roughSizeOfObject(measure)}</span>
                        </div>
                        <div className={classes.parameter}>
                            {measure.parameter}
                        </div>
                    </TableCell>
                    <TableCell align="left">
                        {measure.results[measure.results.length - 1].date}
                    </TableCell>
                    <TableCell align="left">
                        {measure.results[measure.results.length - 1].result} {measure.unit}
                    </TableCell>
                    <TableCell align="left">
                        <Add
                            onClick={() => {
                                params.addMeasurement(measure);
                            }}
                        />
                    </TableCell>
                </TableRow>
                {this.state.open && measure.results.length < 100 &&
                    (
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                {
                                    measure.results.map((result, index2) =>
                                        <Table key={index2}>
                                            <TableRow key={measure.lab + '-' + measure.device + '-' + index + '-' + index2}>
                                                <TableCell component="th" scope="row">
                                                    {result.date}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {result.result}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {result.unit}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Delete
                                                        onClick={() => {
                                                            params.deleteMeasurement({ measure: measure, index: index2 });
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    )
                }
            </>
        );
    }
}

export default TableExpandingRows;